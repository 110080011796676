import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'gatsby';

const ChatLink = ({ href, target, children, ...props }) => {
    if ( target !== '_blank' || href.startsWith('/') || href.match(document.location.hostname)) {
        return <Link to={href} {...props}>{children}</Link>;
    }
    return <a href={href} target={target} {...props}>{children}</a>;
}

Proptypes.ChatLink = {
    href: Proptypes.string.isRequired,
    target: Proptypes.string,
    children: Proptypes.node.isRequired
}

export default ChatLink;
