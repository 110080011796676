import React, {useMemo, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import ChatUI from '@quickenloans-ui-suite/chat';
import ChatLink from '../../Atoms/ChatLink';

const ChatWrapper = () => {
    const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';
    const overrides = {
        domainOverride: {
            ...(activeEnv === 'qa' && {'www.quickenloans.com': 'qa.quickenloans.com'})
        }
    };
    const portal = useMemo(
        () => {
            if (!document.querySelector('#ql-assistant'))
            {
                return ReactDOM.createPortal(<div id="ql-assistant"><ChatUI link={ChatLink} overrides={overrides}/>
                </div>, document.body);
            }
        }
    , []);

    return <>{portal}</>;
}

export default ChatWrapper;
