import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const VideoContext = createContext();
const allYTPlayers = {};

export const useVideoContext = () => useContext(VideoContext);

export const VideoProvider = ({ children }) => 
{
    const [isWindowDefined, setIsWindowDefined] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState(null);

    if (typeof window !== 'undefined')
    {
        useEffect(() => {
            if (typeof window?.YT?.Player !== 'undefined') {
                setIsWindowDefined(true);
            }
        }, [window?.YT]);
    
        const pauseVideos = (vidId) => 
        {
            Object.entries(allYTPlayers).forEach((player) => 
            {
                if (player[1]?.pauseVideo !== undefined && player[0] !== vidId) 
                {
                    player[1].pauseVideo();
                }
            });
        };
    
        const onPlayerStateChange = (event, vidId) => 
        {
            if (event.data === window.YT.PlayerState.PLAYING) 
            {
                pauseVideos(vidId)
            }
        };
    
        const onIframeAdded = (vidId) => 
        {
            if (typeof window?.YT?.Player === 'undefined' && !isWindowDefined) return;
    
            const newIframe = document.querySelector(`#video-container-${vidId} iframe`);
            
            allYTPlayers[vidId] = new window.YT.Player(newIframe, {
                videoId: vidId,
                events: {
                    'onStateChange': (e) => onPlayerStateChange(e, vidId),
                }
            });
    
            newIframe.setAttribute('id', vidId);
            pauseVideos(vidId);
            setCurrentVideoId(vidId);
        };
    
        return (
            <VideoContext.Provider value={{ currentVideoId, onIframeAdded }}>
                {children}
            </VideoContext.Provider>
        );
    };

    return null;
};

VideoProvider.propTypes = {
    /** children, for videoContext */
    children: PropTypes.node,
};