const urlsToExecuteUpdate = require("./Constants");

const onRouteUpdate = async ({ location, prevLocation }) =>
{
    if (window && shouldReloadOnRouteUpdate(location, prevLocation))
    {
        window.location.reload();
    }
}

const appendSlashToUrls = (urls) => urls?.map(url => [url, `${url}/`]).flat();

const shouldReloadOnRouteUpdate = (location, prevLocation) =>
{
    return (
        location &&
        prevLocation &&
        location?.pathname !== prevLocation?.pathname &&
        appendSlashToUrls(urlsToExecuteUpdate).includes(location.pathname)
    );
}

module.exports = { onRouteUpdate, appendSlashToUrls }