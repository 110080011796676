const legacyPageNames = {
    '/': 'ql:home',
    '/about': 'ql:about',
    '/about/contact': 'ql:about:contact',
    '/mortgage-rates': 'ql:mortgage rates',
    '/mortgage-calculators': 'ql:mortgage calculators',
    '/mortgage-amortization-calculator': 'ql:mortgage amortization calculator',
    '/calculator-mortgage': 'ql:calculator mortgage',
    '/calculator-refinance': 'ql:calculator refinance',
    '/rent-vs-buy-calculator': 'ql:rent vs buy calculator',
    '/calculator-affordability': 'ql:calculator affordability',
    '/home-loans':'ql:home loans',
    '/home-loans/30-year-fixed': 'ql:home loans:30 year fixed',
    '/home-loans/15-year-fixed': 'ql:home loans:15 year fixed',
    '/home-loans/adjustable-rate-mortgage': 'ql:home loans:adjustable rate mortgage',
    '/home-loans/25-year-fixed': 'ql:home loans25 year fixed',
    '/home-loans/20-year-fixed': 'ql:home loans:20 year fixed',
    '/home-loans/fha-loan': 'ql:home loans:fha loan',
    '/home-loans/fha-streamline': 'ql:home loans:fha streamline',
    '/home-loans/va-loan': 'ql:home loans:va loan',
    '/home-loans/jumbo-mortgage': 'ql:home loans:jumbo mortgage',
    '/home-loans/custom-mortgage-yourgage': 'ql:home loans:custom mortgage yourgage',
    '/404': 'ql:not found'
}
const getLegacyPageName = ( pathName ) =>
{
    if ( pathName.substring(0,4) === '/404' ) return legacyPageNames['/404'];
    const unslashed = pathName.replace(/\/$/,'') || '/';
    let legacyPageName = legacyPageNames[ unslashed ]
    if ( !legacyPageName ) legacyPageName = `ql:${unslashed.replace(/^\//,'').replace(/\//g,':').replace(/\-/g,' ')}`
    if ( !legacyPageName.length ) legacyPageName = 'ql:unknown';
    return legacyPageName;
}

export default getLegacyPageName;