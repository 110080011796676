/* eslint-disable react/no-danger */
import React from 'react';
import getLegacyPageName from './getLegacyPageName';

const buildEnvs = require('../../../_envs.json');

const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';

const useTags = (pathname) =>
{
    const adobeSrc = buildEnvs[ activeEnv ].ADOBE_SRC;
    // ENABLE TO BRING OPTIMIZELY BACK!
    // const optimizelySrc = buildEnvs[ activeEnv ].OPTIMIZELY_SRC;

    const datadogClientToken = buildEnvs[ activeEnv ].DATADOG_CLIENT_TOKEN;
    const datadogAppId = buildEnvs[ activeEnv ].DATADOG_APP_ID;
    const datadogService = buildEnvs[ activeEnv ].DATADOG_SERVICE;
    const datadogEnv = buildEnvs[ activeEnv ].DATADOG_ENV;

    const headComponents = [];
    let sendPageView = null;

    const legacyPageName = getLegacyPageName( pathname );

    if ( datadogClientToken )
    {
        headComponents.push(
            <script dangerouslySetInnerHTML={{__html: `
            (function(h,o,u,n,d) {
                h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                d=o.createElement(u);d.async=1;d.src=n
                n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
              })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
              window.DD_RUM.onReady(function() {
                window.DD_RUM.init({
                  clientToken: '${datadogClientToken}',
                  applicationId: '${datadogAppId}',
                  site: 'datadoghq.com',
                  service: '${datadogService}',
                  env: '${datadogEnv}',
                  version: '1.0.0',
                  sessionSampleRate: 100,
                  sessionReplaySampleRate: 100,
                  trackUserInteractions: true,
                  trackResources: true,
                  trackLongTasks: true,
                  defaultPrivacyLevel: 'mask-user-input',
                });
              })            
            `}} />
        );
    }

    // ENABLE TO BRING OPTIMIZELY BACK!
    // if ( optimizelySrc)
    // {
    //     headComponents.push(<script src={ optimizelySrc } />);
    // }

    if ( adobeSrc )
    {
        headComponents.push(
            <script src={ adobeSrc } async />
        );
        headComponents.push(
            <script 
                dangerouslySetInnerHTML={{__html: `
                    function qlAdobePageView( pageName ) {
                        var adobeDataLayer = window.adobeDataLayer = window.adobeDataLayer || [];
                        adobeDataLayer.push({
                            "event": "PAGEVIEW",
                            "metadata": {
                                "page_name": pageName
                            }
                        })
                    }
                `}}
            />
        );
        sendPageView = async () => 
        {
            if ( typeof window !== 'undefined' && window.qlAdobePageView ) window.qlAdobePageView( legacyPageName )
        }
    }

    return {headComponents, sendPageView}
}

export default useTags;
