import '@cdm/quickenloans-library/lib/index.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import WebFont from 'webfontloader';
import { onRouteUpdate as onRouteUpdateFn } from './gatsby-apis/onRouteUpdate';
import ChatWrapper from "./src/components/Molecules/ChatWrapper/ChatWrapper";
import './src/StyleGuide/styles/global.qld.scss';
import './src/StyleGuide/styles/global.scss';
import './src/StyleGuide/styles/global.typography.scss';
import useTags from './src/utilities/useTags';
import { VideoProvider } from './src/context/videoContext';

const typekitId = 'cie5zve';

WebFont.load({
    typekit: {
        id: typekitId
    }
})

export const wrapRootElement = ({ element }) => (
    <VideoProvider>
        <ChatWrapper />
        <CookiesProvider>{element}</CookiesProvider>
    </VideoProvider>
);

const AdobePageViewTrigger = ({props, element}) =>
{
    const pathname = props?.pageResources?.page?.path || 'unknown';
    const {sendPageView} = useTags( pathname )
    sendPageView();
    return element;
}

export const wrapPageElement = ( args ) =>
    (
        <AdobePageViewTrigger {...args} />
    )

export const onRouteUpdate = onRouteUpdateFn;